import Rails from "@rails/ujs"
Rails.start()

import "@hotwired/turbo-rails"
import "@asgerb/apparatus/package/dist/application.js"
import "@github/tab-container-element"
import "flickity"

import "channels"
import "controllers"

require.context("../favicons", true)
require.context("../icons", true)
require.context("../images", true)
require.context("../svgs", true)
