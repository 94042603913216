import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "list"]
  static values = {
    order: String,
  }

  connect() {
    this._sortChildren()
  }

  changeOrder() {
    if (this.isAscending) {
      this.orderValue = "desc"
    } else {
      this.orderValue = "asc"
    }
  }

  orderValueChanged() {
    this._sortChildren()
  }

  get isAscending() {
    return this.orderValue === "asc"
  }

  get isDescending() {
    return this.orderValue === "desc"
  }

  get sortOrder() {
    if (this.isAscending) {
      return -1
    } else {
      return 0
    }
  }

  // Private

  _sortChildren() {
    const { children } = this
    if (this.elementsAreSorted(children)) return
    children
      .sort((left, right) => this.compareElements(left, right))
      .forEach(this.append)
  }

  get children() {
    return this.itemTargets
  }

  append = (child) => this.listTarget.append(child)

  elementsAreSorted([left, ...rights]) {
    for (const right of rights) {
      if (this.compareElements(left, right) > 0) return false
      left = right
    }
    return true
  }

  compareElements(left, right) {
    if (this.isAscending) {
      return this.getSortCode(left) - this.getSortCode(right)
    } else {
      return this.getSortCode(right) - this.getSortCode(left)
    }
  }

  getSortCode(element) {
    return element.getAttribute("data-sort-code") || 0
  }
}
