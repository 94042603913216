import { Controller } from "@hotwired/stimulus"
import { useResize } from "stimulus-use"

export default class extends Controller {
  static targets = ["text"]
  static values = { minSize: Number, maxSize: Number }

  connect() {
    useResize(this)
  }

  resize() {
    this.clearContainerBoundingClientRect()
    this.fit()
  }

  fit() {
    let min = this.minSize
    let max = this.maxSize
    let size
    while (min <= max) {
      let mid = (max + min) >> 1
      this.fontSize = mid
      if (this.isContained) {
        min = mid + 1
      } else {
        max = mid - 1
      }
      size = mid
    }
    const maxAttempts = 5
    let i = 0
    do {
      size = size - 1
      i++
    } while (!this.isContained && i < maxAttempts)
    this.fontSize = size
  }

  clearContainerBoundingClientRect() {
    if (!this._containerBoundingClientRect) return
    this._containerBoundingClientRect = null
  }

  set fontSize(size) {
    this.textTarget.style.fontSize = `${size}px`
  }

  get isContained() {
    const textRect = this.textTarget.getBoundingClientRect()
    return textRect.width <= this.maxWidth && textRect.height <= this.maxHeight
  }

  get maxHeight() {
    return this.containerBoundingClientRect.height
  }

  get maxWidth() {
    return this.containerBoundingClientRect.width
  }

  get minSize() {
    return this.minSizeValue || 6
  }

  get maxSize() {
    return this.maxSizeValue || 300
  }

  get containerBoundingClientRect() {
    if (!this._containerBoundingClientRect) {
      this._containerBoundingClientRect = this.element.getBoundingClientRect()
    }
    return this._containerBoundingClientRect
  }
}
