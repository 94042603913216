import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["success", "error", "loading", "flipped"]
  static targets = ["form"]

  success() {
    this.element.classList.remove(this.errorClass)
    this.element.classList.add(this.flippedClass)
    this.element.classList.add(this.successClass)
    this.element.classList.remove(this.loadingClass)
  }

  error() {
    if (this.form) this.form.reset()
    this.element.classList.remove(this.loadingClass)
    this.element.classList.remove(this.successClass)
    this.element.classList.add(this.flippedClass)
    this.element.classList.add(this.errorClass)
  }

  loading() {
    this.element.classList.add(this.loadingClass)
  }

  close() {
    this.element.classList.remove(this.flippedClass)
    this.element.classList.remove(this.loadingClass)
  }

  get form() {
    return this.element.querySelector("form")
  }
}
