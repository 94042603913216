import { Controller } from "@hotwired/stimulus"
import { useMutation } from "stimulus-use"

export default class extends Controller {
  static targets = ["container", "item"]

  connect() {
    useMutation(this, {
      element: this.containerTarget,
      attributes: true,
      childList: false,
      subtree: true,
    })
  }

  mutate() {
    this.element.hidden = this.itemTargets.reduce(
      (sum, item) => sum && item.hidden,
      true
    )
  }
}
