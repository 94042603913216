import { Controller } from "@hotwired/stimulus"
import Fuse from "fuse.js"

export default class extends Controller {
  static targets = ["item"]

  filter({ target: { value } }) {
    if (value) {
      const options = {
        includeScore: true,
        ignoreLocation: true,
        ignoreFieldNorm: true,
        threshold: 0.1,
        keys: ["value"],
      }
      const fuse = new Fuse(this.searchItems, options)
      const results = fuse.search(value).map((result) => result.item.element)
      this.itemTargets.forEach(
        (item) => (item.hidden = !results.includes(item))
      )
    } else {
      this.itemTargets.forEach((item) => (item.hidden = false))
    }
  }

  get searchItems() {
    return this.itemTargets.map((item) => this._buildSearchItem(item))
  }

  _buildSearchItem(element) {
    return {
      value: element.dataset.fuzzySearchValue,
      element: element,
    }
  }
}
