import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  customVar = "--vh"

  connect() {
    this.doc = document.documentElement
    this.window = window
    this.update("--vh")
  }

  update() {
    requestAnimationFrame(() => {
      const innerHeight = this.innerHeight
      if (innerHeight === this.previousClientHeight) return
      this._setVariable(this.customVar, innerHeight)
      this.previousClientHeight = this.innerHeight
    })
  }

  _setVariable(variableName, innerHeight) {
    this.doc.style.setProperty(variableName, `${innerHeight * 0.01}px`)
  }

  get innerHeight() {
    return this.window.innerHeight
  }
}
