import { Controller } from "@hotwired/stimulus"
import Flickity from "flickity"

export default class extends Controller {
  static values = {
    adaptiveHeight: Boolean,
    arrowShape: Object,
    cellSelector: String,
    draggable: Boolean,
    groupCells: Boolean,
    initialIndex: Number,
    isFirstSlide: Boolean,
    isLastSlide: Boolean,
    pageDots: Boolean,
    prevNextButtons: Boolean,
    setGallerySize: Boolean,
    wrapAround: Boolean,
  }

  connect() {
    this.initializeFlickity()
  }

  disconnect() {
    if (this.flickity) this.flickity.destroy()
  }

  initializeFlickity() {
    this.flickity = new Flickity(this.element, this.flickityOptions)
  }

  resize() {
    if (!this.flickity) return
    this.flickity.resize()
  }

  get flickityOptions() {
    return {
      cellSelector: `.${this.cellSelectorValue}`,
      ...(this.hasAdaptiveHeightValue && {
        adaptiveHeight: this.adaptiveHeightValue,
      }),
      ...(this.hasGroupCellsValue && {
        groupCells: this.groupCellsValue,
      }),
      ...(this.hasDraggableValue && {
        draggable: this.draggableValue,
      }),
      ...(this.hasInitialIndexValue && {
        initialIndex: this.initialIndexValue,
      }),
      ...(this.hasPageDotsValue && {
        pageDots: this.PageDotsValue,
      }),
      ...(this.hasPrevNextButtonsValue && {
        prevNextButtons: this.prevNextButtonsValue,
      }),
      ...(this.hasSetGallerySizeValue && {
        setGallerySize: this.setGallerySizeValue,
      }),
    }
  }

  get selectedIndex() {
    return this.flickity.selectedIndex
  }
}
