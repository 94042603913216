import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import { registerControllers } from "@asgerb/apparatus-layout"

const application = Application.start()
const controllers = require.context(".", true, /_controller\.js$/)
const componentControllers = require.context(
  "../../components",
  true,
  /_controller\.js$/
)

application.load(definitionsFromContext(controllers))
application.load(definitionsFromContext(componentControllers))
registerControllers(application)
application.warnings = false
