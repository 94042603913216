import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String, props: Object }

  async connect() {
    const { default: component } = await import(
      `../svelte/${this.nameValue}/index.svelte`
    )
    new component({
      target: this.element.parentElement,
      anchor: this.element,
      props: this.propsValue,
    })
    this.element.remove()
  }
}
