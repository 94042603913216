import { Controller } from "@hotwired/stimulus"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"

export default class extends Controller {
  static targets = ["scrollable"]

  disconnect() {
    this.clearAllLocks()
  }

  disableScroll() {
    disableBodyScroll(this.scrollableElement)
  }

  enableScroll() {
    enableBodyScroll(this.scrollableElement)
  }

  clearAllLocks() {
    clearAllBodyScrollLocks(this.scrollableElement)
  }

  get scrollableElement() {
    if (this.hasScrollableTarget) {
      return this.scrollableTarget
    } else {
      this.element
    }
  }
}
