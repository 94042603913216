import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["image"]
  static classes = ["unveiled", "loaded"]

  unveil() {
    lazySizes.loader.unveil(this.imageTarget)
  }

  unveiled() {
    this.element.classList.add(this.unveiledClass)
  }

  loaded() {
    this.element.classList.add(this.loadedClass)
  }
}
